import * as z from 'zod';

const baseSchema = z.object({
  description: z.string().min(1, 'Detalles son requeridos'),
});

export const dentistRevisionSchema = baseSchema;

export const sellerRevisionSchema = baseSchema.extend({
  deliveryDateIntent: z.string().min(1, 'Fecha de entrega es requerida'),
});
