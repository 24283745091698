import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createOffer } from '@/services/offer';
import { getCommissionplans } from '@/services/commissionplan';
import { getReferrals } from '@/services/referral';
import io from 'socket.io-client';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';

const OfferFormDialog = ({ demand, isOpen, setIsOpen, user }) => {
  const deliveryDate = new Date(demand?.deliveryDate)
    .toISOString()
    .split('T')[0];
  const [description, setDescription] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const [commissionplanCommission, setCommissionplanCommission] =
    useState(null);
  const [sellerCommission100, setSellerCommission] = useState(null);
  const [referrals, setReferrals] = useState([]);
  // const [delivery, setDelivery] = useState(3.5);
  const [deliveryDateIntent, setDeliveryDateIntent] = useState(deliveryDate);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const today = new Date().toISOString().split('T')[0];
  const socket = useRef(null);

  const handleDialogClose = () => setIsOpen(false);

  const resetForm = () => {
    setDescription('');
    setSubTotal(0);
    // setDelivery(3.5); // Reset to default delivery cost
    setDeliveryDateIntent('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!demand) {
      toast.error('Case not found');
      setLoading(false);
      return;
    }

    const offerData = {
      demand: demand._id,
      description,
      deliveryDateIntent,
      total,
      subTotal: parseFloat((subTotal - calculatedCommission).toFixed(2)),
      // delivery,
      commission: calculatedCommission,
      selectedTeeth: demand.selectedTeeth,
    };

    try {
      const response = await createOffer(offerData);
      if (response) {
        toast.success('Propuesta creada exitosamente');
        socket.current.emit('createOffer', {
          offer: response.offer,
          userId: demand.user?._id,
        });
        resetForm();
        setIsOpen(false);
        navigate(`/cases/offer/${response.offer._id}`);
      }
    } catch (error) {
      console.error('Failed to create offer:', error);
      toast.error(error.response?.data?.msg || 'Failed to create offer');
    } finally {
      setLoading(false);
    }
  };

  // Memoize the calculated commission based on the commission plan and referrals
  const calculatedCommission = useMemo(() => {
    let sellerCommission = 0.15;
    if (commissionplanCommission) {
      sellerCommission = commissionplanCommission / 100;
    } else {
      sellerCommission = (sellerCommission100 || 15) / 100;
    }

    const referral = referrals.find((ref) => ref.user === demand.user);
    if (referral) {
      sellerCommission = 0;
    }

    return subTotal * sellerCommission;
  }, [
    subTotal,
    commissionplanCommission,
    sellerCommission100,
    referrals,
    demand,
  ]);

  // Memoize the total price calculation
  const total = useMemo(() => {
    const computedTotal = Number(subTotal);
    //+ delivery;
    return isNaN(computedTotal) ? 0 : computedTotal; // Ensure total is a number
  }, [subTotal, calculatedCommission]);

  const fetchCommissionplans = async () => {
    setLoading(true);
    try {
      const response = await getCommissionplans({});
      if (response.commissionPlans[0]) {
        setCommissionplanCommission(response.commissionPlans[0].commission);
      }
      setSellerCommission(response.sellerCommission);
    } catch (error) {
      console.error('Failed to fetch commission plans:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReferrals = async () => {
    setLoading(true);
    try {
      const response = await getReferrals({});
      if (response) {
        setReferrals(response.referrals);
      } else {
        toast.error('No referrals found');
      }
    } catch (error) {
      console.error('Failed to fetch referrals:', error);
      toast.error('Failed to fetch referrals');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommissionplans();
    fetchReferrals();
  }, []);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });
    socket.current.emit('joinRoom', demand._id);

    return () => {
      socket.current.disconnect();
    };
  }, [demand]);

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      <DialogContent>
        <DialogTitle className="flex justify-between items-center">
          Crear Propuesta
        </DialogTitle>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Descripción"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              rows={4}
              disabled={loading}
            />
          </div>
          <div>
            <div className="flex flex-row gap-2">
              <Label>Fecha de entrega</Label>

              <HoverCard>
                <HoverCardTrigger>
                  <IoInformationCircleOutline />
                </HoverCardTrigger>
                <HoverCardContent>
                  <p className="text-justify">
                    Para calcular la fecha de entrega debes tener en cuenta que
                    el tiempo en tránsito para la península es de 24-48h
                  </p>
                </HoverCardContent>
              </HoverCard>
            </div>
            <Input
              type="date"
              value={deliveryDateIntent}
              onChange={(e) => setDeliveryDateIntent(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              min={today}
              disabled={loading}
            />
          </div>
          <div>
            <div className="flex flex-row gap-2">
              <Label>Presupuesto trabajo + envío</Label>
              <HoverCard>
                <HoverCardTrigger>
                  <IoInformationCircleOutline />
                </HoverCardTrigger>
                <HoverCardContent>
                  <p className="text-justify">
                    El coste del envío va a cargo del laboratorio, ten en cuenta
                    que el precio de envíos a la península oscila entre 4 y 5€
                    más IVA pudiendo ser más dependiendo de la empresa de
                    transporte
                  </p>
                </HoverCardContent>
              </HoverCard>
            </div>

            <div className="relative">
              <Input
                type="text"
                value={subTotal === 0 ? '' : subTotal.toString()} // Show current value as string
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^\d*\.?\d{0,2}$/; // Allow digits and up to 2 decimal places

                  // If the input matches the regex (valid number format) or is empty
                  if (value === '' || regex.test(value)) {
                    setSubTotal(value); // Set value as is (string)
                  }
                }}
                onBlur={() => {
                  // Convert the input value to a float with two decimal places on blur
                  setSubTotal((prevValue) =>
                    prevValue === '' ? 0 : parseFloat(prevValue).toFixed(2),
                  );
                }}
                className="mt-1 block w-full pr-5 border border-gray-300 rounded-lg shadow-sm text-right "
                disabled={loading}
              />

              <span className="absolute right-2 top-2/4 transform -translate-y-2/4 text-gray-500 ">
                €
              </span>
            </div>
          </div>
          {/* <div className="flex justify-end text-xs">
            <div className="text-right">
              <Label>Total a recibir</Label>
              <div>{subTotal - calculatedCommission.toFixed(2)} €</div>
            </div>
          </div> */}
          {/* <div className="flex justify-end text-xs">
            <div className="text-right">
              <Label>Comisión</Label>
              <div>{calculatedCommission.toFixed(2)} €</div>
            </div>
          </div> */}
          {/* <div className="flex justify-end text-xs">
            <div className="text-right">
              <Label>Envío</Label>
              <div>{delivery.toFixed(2)} €</div>
            </div>
          </div> */}
          {/* <div className="flex justify-end text-xs">
            <div className="text-right">
              <Label>Total a recibir (Envío incluido)</Label>
              <div>
                {Number((subTotal - calculatedCommission).toFixed(2))} €
              </div>
            </div>
          </div> */}

          <div>
            <Button
              type="submit"
              className="w-full bg-blue-500 text-white hover:bg-blue-400"
              disabled={
                loading || !description || !deliveryDateIntent || subTotal <= 0
              }
            >
              {loading ? 'Creando...' : 'Crear Propuesta'}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OfferFormDialog;
