import React, { useEffect, useRef } from 'react';
import { useUserStore } from '@/store/userStore';
import { Link } from 'react-router-dom';
import CookiesNotificationModal from '@/components/Modals/CookiesNotificationModal';
import Footer from '@/components/Cards/Footer';
import grid_1 from '@/assets/grid_1.png';
import grid_2 from '@/assets/grid_2.png';
import grid_3 from '@/assets/grid_3.png';
import grid_4 from '@/assets/grid_4.png';
import grid_5 from '@/assets/grid_5.png';
import grid_6 from '@/assets/grid_6.png';
import grid_7 from '@/assets/grid_7.png';

import icon_1 from '@/assets/icon_1.png';
import icon_2 from '@/assets/icon_2.png';
import icon_3 from '@/assets/icon_3.png';
import icon_4 from '@/assets/icon_4.png';
import icon_5 from '@/assets/icon_5.png';
import icon_6 from '@/assets/icon_6.png';
import icon_7 from '@/assets/icon_7.png';
import hero from '@/assets/hero.png';
import FAQ from '@/components/FAQ';

function HomePage() {
  const { askCookies, setAskCookies, setAllowCookies } = useUserStore();
  const formRef = useRef(null);

  useEffect(() => {
    // Load Zoho Campaigns script
    const script = document.createElement('script');
    script.src = 'https://zcv2-zcmp.maillist-manage.eu/js/optin.min.js';
    script.onload = () => {
      window.setupSF(
        'sf3z91c4b715f7da7f74ce0a300e209eda078872144d5fd6ba410d8eb2f977fcb1d1',
        'ZCFORMVIEW',
        false,
        'light',
        false,
        '0',
      );
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white">
      {!askCookies && (
        <CookiesNotificationModal
          setAllowCookies={setAllowCookies}
          setAskCookies={setAskCookies}
        />
      )}

      <div className="flex justify-center px-6 md:px-16 pt-16 gap-8">
        <div className="flex w-full flex-col-reverse md:flex-row gap-4  ">
          <div className="flex-1 flex flex-col justify-center">
            <h1 className="font-urbanist font-semibold  text-4xl md:text-5xl  lg:text-7xl text-[#010E42]">
              Por fin, todos tus trabajos dentales en una sola plataforma.
            </h1>
            <p className="font-inter font-medium text-base md:text-xl text-[#3E4F6A] opacity-50 mt-5 mb-10">
              Adiós a las palomas mensajeras. Hola a la gestión dental del
              futuro.
            </p>

            <Link
              to="/login"
              className="bg-[#355DFD] text-white rounded-md px-4 py-3 text-base  w-full md:w-fit"
            >
              Prueba gratis
            </Link>
          </div>
          <div className="flex-1 flex justify-center w-full md:w-[38%]">
            <img src={hero} alt="hero oklusion" className="w-full h-auto" />
          </div>
        </div>
      </div>

      <section className="px-10 py-2">
        <div className="flex  justify-center ">
          <h2 className="font-semibold font-urbanist text-3xl md:text-5xl my-[50px]">
            Tu trabajo de siempre, pero más fácil.
          </h2>
        </div>

        {/* nueva */}
        <div className="flex flex-col md:flex-row w-full gap-2">
          <div className="flex flex-col w-full md:w-2/3 gap-2 ">
            <div className="flex flex-col md:flex-row md:h-2/3 gap-2 ">
              <div className="bg-[#355dfc] w-full md:w-1/2 rounded-md py-2 flex flex-col justify-around gap-3">
                <div className="mx-8">
                  <div className="flex flex-row  mt-4 gap-2 items-center">
                    <img src={icon_1} alt="icon_1" className="w-10 h-10 " />
                    <h3 className="text-white text-2xl md:text-3xl font-semibold text-start ">
                      Chat con los laboratorios
                    </h3>
                  </div>
                  <p className="text-white  text-lg md:text-xl  font-semibold py-2">
                    0 llamadas, 100% chat. Así de fácil.
                  </p>
                </div>
                <img
                  src={grid_1}
                  alt="Logo Oklusion"
                  className="px-5 md:px-0w-full md:w-[95%]  mb-5 md:mb-20 mx-auto"
                />
              </div>

              <div className="flex flex-col w-full md:w-1/2 justify-between gap-2 ">
                <div className="bg-[#c09cff] rounded-md h-1/2 flex flex-col justify-between  pb-6 md:pb-0 md:pt-2">
                  <div className="mx-5 md:mx-6 gap-2">
                    <div className="flex flex-row   items-center gap-2 mt-3">
                      <img src={icon_2} alt="icon_1" className="w-10 h-10" />
                      <h3 className=" text-xl md:text-2xl font-semibold  text-start">
                        Valoraciones de labs
                      </h3>
                    </div>
                    <p className="font-inter  text-xs md:text-base font-normal text-start ">
                      Match con los mejores Labs. Consulta valoraciones y
                      asegura el tiro.
                    </p>
                  </div>

                  <div className="flex justify-center items-center">
                    <img
                      src={grid_2}
                      alt="Logo Oklusion"
                      className="px-3 mb-0  md:w-4/5  mx-auto"
                    />
                  </div>
                </div>

                <div className="bg-[#70c5fa] rounded-md h-1/2 flex flex-col justify-between pb-4 md:pb-0 pt-2">
                  <div className="mx-5  ">
                    <div className="flex flex-row  md:my-2  items-center gap-2">
                      <img src={icon_4} alt="icon_1" className="w-10 h-10" />
                      <h3 className=" text-xl md:text-2xl font-semibold text-start">
                        Múltiples presupuestos
                      </h3>
                    </div>
                    <p className="font-inter  text-base md:text-lg font-normal text-start">
                      Múltiples presupuestos. La mejor oferta sin esfuerzo.
                    </p>
                  </div>

                  <div className="flex justify-center items-center">
                    <img
                      src={grid_4}
                      alt="Logo Oklusion"
                      className=" px-2 mb-0  md:w-4/5  mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#f7ec8b] flex flex-col md:h-1/3 md:flex-row w-full p-4 rounded-md justify-center ">
              <div className="flex flex-col mx-2  w-full md:w-1/2  justify-center gap-4">
                <div className="flex flex-row justify-center md:justify-start items-center gap-2">
                  <img src={icon_6} alt="icon_1" className="w-10 h-10 " />
                  <h3 className=" text-xl md:text-2xl font-semibold py-2 text-start">
                    Monitoreo de trabajos a tiempo real
                  </h3>
                </div>
                <h2 className="font-inter  text-base md:text-xl  font-normal text-start ">
                  Sigue el estado de tus trabajos. Sin llamadas. Sin esperas. 
                </h2>
              </div>
              <img
                src={grid_6}
                alt="Logo Oklusion"
                className="px-3 md:px-1 w-full md:w-1/2 py-4"
              />
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/3 justify-between gap-2">
            <div className="bg-[#f7ec8b] rounded-md w-full h-1/3 flex flex-col justify-between pt-2 pb-6 md:pb-0">
              <div className="flex flex-row justify-start items-center mx-5  mt-5 gap-1">
                <img src={icon_3} alt="icon_1" className="w-10 h-10 " />
                <h3 className=" text-xl md:text-xl font-semibold  text-start">
                  Tus datos cumpliendo con la normativa y sin estrés
                </h3>
              </div>

              <div className="flex justify-center items-center">
                <img
                  src={grid_3}
                  alt="Logo Oklusion"
                  className=" px-6 md:px-0 w-full md:w-[68%]"
                />
              </div>
            </div>

            <div className="bg-[#355dfc] rounded-md w-full  h-1/3 py-2">
              <div className="flex flex-row justify-start items-center gap-2 my-4 mx-5 md:mx-8">
                <img src={icon_5} alt="icon_1" className="w-10 h-10 " />
                <h3 className="text-xl md:text-2xl font-semibold text-white text-start">
                  Métricas inteligentes
                </h3>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={grid_5}
                  alt="Logo Oklusion"
                  className="px-6 md:px-2 w-full md:w-[71%]"
                />
              </div>
            </div>

            <div className="bg-[#c09cff] rounded-md w-full h-1/3 flex flex-col justify-between md:pt-2 gap-2">
              <div className="mx-5  my-2">
                <div className="flex flex-row  mt-4 gap-2">
                  <img src={icon_7} alt="icon_1" className="w-10 h-10 " />
                  <h3 className=" text-xl md:text-2xl font-semibold py-2 text-start">
                    Versión responsive
                  </h3>
                </div>
                <p className="font-inter  text-sm md:text-xl  font-normal text-start ">
                  También desde el teléfono. Obvio, no?
                </p>
              </div>

              <div className="flex justify-center items-center">
                <img
                  src={grid_7}
                  alt="Logo Oklusion"
                  className="px-6  mb-0 w-full md:w-4/5  mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full bg-[#355dfc] flex justify-center items-center mb-0 mt-[80px]">
        <div className="flex flex-col md:flex-row justify-between items-center py-20 w-4/5 max-w-screen-lg gap-8">
          <div className="space-y-4 md:w-2/3 text-center md:text-left">
            <p className="text-5xl  text-white font-semibold">
              Webinar Newsletter&nbsp;
            </p>
            <p className=" text-lg md:text-xl font-medium text-white md:w-3/4 mx-auto md:mx-0">
              ¡Únete a la élite dental! Accede a webinars con expertos que te
              ayudarán a llevar tu práctica al siguiente nivel. (es
              gratis!!!)&nbsp;
            </p>
          </div>
          <div className="cta_right">
            <div className="cta_right_center">
              {/*Zoho Campaigns Web-Optin Form Starts Here*/}
              <div
                id="sf3z91c4b715f7da7f74ce0a300e209eda078872144d5fd6ba410d8eb2f977fcb1d1"
                data-type="signupform"
              >
                <div id="customForm">
                  <div className="quick_form_5_css" name="SIGNUP_BODY">
                    <div>
                      <div id="SIGNUP_HEADING" className="hidden">
                        Join Our Newsletter
                      </div>
                      <div style={{ position: 'relative' }}>
                        <div
                          id="Zc_SignupSuccess"
                          style={{
                            display: 'none',
                            position: 'absolute',
                            marginLeft: '4%',
                            width: '90%',
                            backgroundColor: 'white',
                            padding: 3,
                            border: '3px solid rgb(194, 225, 154)',
                            marginTop: 10,
                            marginBottom: 10,
                            wordBreak: 'break-all',
                          }}
                        >
                          <table
                            width="100%"
                            cellPadding={0}
                            cellSpacing={0}
                            border={0}
                          >
                            <tbody>
                              <tr>
                                <td width="10%">
                                  <img
                                    className="successicon"
                                    src="https://zcv2-zcmp.maillist-manage.eu/images/challangeiconenable.jpg"
                                    align="absmiddle"
                                  />
                                </td>
                                <td>
                                  <span
                                    id="signupSuccessMsg"
                                    style={{
                                      color: 'rgb(73, 140, 132)',
                                      fontFamily: 'sans-serif',
                                      fontSize: 14,
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    &nbsp;&nbsp;Thank you for Signing Up
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <form
                        method="POST"
                        id="zcampaignOptinForm"
                        action="https://zcv2-zcmp.maillist-manage.eu/weboptin.zc"
                        target="_zcSignup"
                      >
                        <div id="errorMsgDiv" className="hidden">
                          Please correct the marked field(s) below.
                        </div>
                        <div className="SIGNUP_FLD">
                          <input
                            type="text"
                            placeholder="Email"
                            changeitem="SIGNUP_FORM_FIELD"
                            name="CONTACT_EMAIL"
                            id="EMBED_FORM_EMAIL_LABEL"
                            className="w-[250px] px-5 py-3 m-2 block border rounded-sm "
                          />
                        </div>
                        <div className="SIGNUP_FLD">
                          <input
                            type="text"
                            placeholder="Name"
                            changeitem="SIGNUP_FORM_FIELD"
                            name="FIRSTNAME"
                            id="FIRSTNAME"
                            className="w-[250px] px-5 py-3 m-2 block border rounded-sm "
                          />
                        </div>
                        <div className="SIGNUP_FLD">
                          <input
                            type="button"
                            name="SIGNUP_SUBMIT_BUTTON"
                            id="zcWebOptin"
                            defaultValue="Join Now"
                            className="w-[250px] bg-[#DCE3FF] hover:bg-[#c4cadf] px-5 py-3 m-2 block border rounded-sm "
                          />
                        </div>
                        <input type="hidden" id="fieldBorder" defaultValue="" />
                        <input
                          type="hidden"
                          id="submitType"
                          name="submitType"
                          defaultValue="optinCustomView"
                        />
                        <input
                          type="hidden"
                          id="emailReportId"
                          name="emailReportId"
                          defaultValue=""
                        />
                        <input
                          type="hidden"
                          id="formType"
                          name="formType"
                          defaultValue="QuickForm"
                        />
                        <input
                          type="hidden"
                          name="zx"
                          id="cmpZuid"
                          defaultValue="14ae2407db"
                        />
                        <input type="hidden" name="zcvers" defaultValue={2.0} />
                        <input
                          type="hidden"
                          name="oldListIds"
                          id="allCheckedListIds"
                          defaultValue=""
                        />
                        <input
                          type="hidden"
                          id="mode"
                          name="mode"
                          defaultValue="OptinCreateView"
                        />
                        <input
                          type="hidden"
                          id="zcld"
                          name="zcld"
                          defaultValue="12de75a89fb53296"
                        />
                        <input
                          type="hidden"
                          id="zctd"
                          name="zctd"
                          defaultValue="12de75a89fb53159"
                        />
                        <input
                          type="hidden"
                          id="document_domain"
                          defaultValue=""
                        />
                        <input
                          type="hidden"
                          id="zc_Url"
                          defaultValue="zcv2-zcmp.maillist-manage.eu"
                        />
                        <input
                          type="hidden"
                          id="new_optin_response_in"
                          defaultValue={0}
                        />
                        <input
                          type="hidden"
                          id="duplicate_optin_response_in"
                          defaultValue={0}
                        />
                        <input
                          type="hidden"
                          name="zc_trackCode"
                          id="zc_trackCode"
                          defaultValue="ZCFORMVIEW"
                        />
                        <input
                          type="hidden"
                          id="zc_formIx"
                          name="zc_formIx"
                          defaultValue="3z91c4b715f7da7f74ce0a300e209eda078872144d5fd6ba410d8eb2f977fcb1d1"
                        />
                        <input
                          type="hidden"
                          id="viewFrom"
                          defaultValue="URL_ACTION"
                        />
                        <span style={{ display: 'none' }} id="dt_CONTACT_EMAIL">
                          1,true,6,Correo electrónico de contacto,2
                        </span>
                        <span style={{ display: 'none' }} id="dt_FIRSTNAME">
                          1,false,1,Nombre,2
                        </span>
                        <span style={{ display: 'none' }} id="dt_LASTNAME">
                          1,false,1,Apellidos,2
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
                <img
                  src="https://zcv2-zcmp.maillist-manage.eu/images/spacer.gif"
                  id="refImage"
                  onload="referenceSetter(this)"
                />
              </div>
              <input
                type="hidden"
                id="signupFormType"
                defaultValue="QuickForm_Horizontal"
              />
              <div
                id="zcOptinOverLay"
                oncontextmenu="return false"
                style={{
                  display: 'none',
                  textAlign: 'center',
                  backgroundColor: 'rgb(0, 0, 0)',
                  opacity: '0.5',
                  zIndex: 100,
                  position: 'fixed',
                  width: '100%',
                  top: 0,
                  left: 0,
                  height: 988,
                }}
              />
              <div
                id="zcOptinSuccessPopup"
                style={{
                  display: 'none',
                  zIndex: 9999,
                  width: 800,
                  height: '40%',
                  top: 84,
                  position: 'fixed',
                  left: '26%',
                  backgroundColor: '#FFFFFF',
                  borderColor: '#E6E6E6',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  boxShadow: '0 1px 10px #424242',
                  padding: 35,
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '-16px',
                    right: '-14px',
                    zIndex: 99999,
                    cursor: 'pointer',
                  }}
                  id="closeSuccess"
                >
                  <img src="https://zcv2-zcmp.maillist-manage.eu/images/videoclose.png" />
                </span>
                <div id="zcOptinSuccessPanel" />
              </div>
              {/*Zoho Campaigns Web-Optin Form Ends Here*/}
            </div>
          </div>
        </div>
      </section>
      <FAQ />

      <Footer />
    </div>
  );
}

export default HomePage;
