import * as z from 'zod';

export const reviewSchema = z.object({
  description: z.string().min(1, 'Por favor, escribe una valoración'),
  rating: z.number().min(1, 'Por favor, selecciona una calificación').max(5),
  isPrivate: z.boolean().default(false),
});

export const reviewEditSchema = z.object({
  description: z.string().min(1, 'Por favor, escribe una valoración'),
  rating: z.number().min(1, 'Por favor, selecciona una calificación').max(5),
  isPrivate: z.boolean().default(false),
});
