import * as z from 'zod';

export const registerSchema = z
  .object({
    email: z.string().email({
      message: 'Por favor, introduce un email válido.',
    }),
    password: z.string().min(8, {
      message: 'La contraseña debe tener al menos 8 caracteres.',
    }),
    confirmPassword: z.string(),
    role: z.enum(['SELLER_ROLE', 'DENTIST_ROLE'], {
      required_error: 'Por favor, selecciona un rol.',
    }),
    terms: z.boolean().refine((val) => val === true, {
      message: 'Debes aceptar los términos y condiciones.',
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Las contraseñas no coinciden',
    path: ['confirmPassword'],
  });
