import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import LogoOklusion from '@/assets/Login.png';
import { useRegister } from '@/hooks/useUser';

const RegisterPage = () => {
  const { form, onSubmit } = useRegister();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-8">
      <div className="flex w-full lg:w-3/4 max-w-6xl shadow-lg rounded-lg overflow-hidden">
        <div
          className="hidden lg:flex w-1/2 items-center justify-center min-h-full"
          style={{
            backgroundImage: `url(${LogoOklusion})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
        <div className="w-full lg:w-1/2 bg-white p-8 rounded-lg items-center justify-center gap-2">
          <div className="max-w-sm mx-auto">
            <h2 className="text-2xl font-bold text-center mb-6">
              Bienvenido a Oklusion
            </h2>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input placeholder="you@example.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contraseña</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="••••••••"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Confirmar contraseña</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="••••••••"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="role"
                  render={({ field }) => (
                    <FormItem className="space-y-3">
                      <FormLabel>Seleccione su categoria</FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className="flex space-x-1"
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="SELLER_ROLE" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Laboratorio
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="DENTIST_ROLE" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Dentista
                            </FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="terms"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>
                          Acepto las{' '}
                          <Link
                            to="doc/condiciones-generales"
                            className="text-blue-600 hover:underline"
                            target="_blank"
                          >
                            Condiciones de servicio
                          </Link>{' '}
                          y{' '}
                          <Link
                            to="/politicas"
                            className="text-blue-600 hover:underline"
                            target="_blank"
                          >
                            Política de privacidad
                          </Link>
                        </FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
                <Button type="submit" className="w-full">
                  Registrarme
                </Button>
              </form>
            </Form>
            <div className="mt-6 text-center">
              <p>
                Ya estas registrado?{' '}
                <Link
                  to="/login"
                  className="text-black hover:text-blue-500 underline"
                >
                  Inicia sesión
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
