import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import LogoOklusion from '@/assets/Login.png';
import { useLogin } from '@/hooks/useUser';

const LoginPage = () => {
  const { form, showVerifyButton, onSubmit, handleRequestVerifyEmail } =
    useLogin();

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-8">
      <div className="flex w-full h-full lg:w-3/4 max-w-6xl shadow-lg rounded-lg overflow-hidden">
        <div
          className="hidden lg:flex lg:w-1/2 items-center justify-center bg-cover bg-center"
          style={{
            backgroundImage: `url(${LogoOklusion})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>

        <div className="w-full lg:w-1/2 bg-white p-8">
          <div className="max-w-sm mx-auto">
            <h2 className="text-2xl font-bold text-center mb-6">
              Bienvenido a Oklusion
            </h2>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input placeholder="you@domain.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="••••••••"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="text-right">
                  <Link
                    to="/forgotpassword"
                    className="text-sm text-black hover:text-blue-500 underline"
                  >
                    Olvidé mi contraseña
                  </Link>
                </div>
                <Button type="submit" className="w-full">
                  Iniciar Sesión
                </Button>
              </form>
            </Form>
            {showVerifyButton && (
              <Button
                variant="link"
                onClick={handleRequestVerifyEmail}
                className="mt-4 text-sm"
              >
                Verificar Email
              </Button>
            )}
            <div className="mt-4 text-center">
              <p className="text-sm text-gray-600">
                ¿No tienes una cuenta aún?{' '}
                <Link
                  to="/register"
                  className="text-black hover:text-blue-500 underline"
                >
                  Registrarme
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
