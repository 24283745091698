import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import useUserStore from '@/store/userStore';
import { useDemandStore } from '@/store/DemandStore';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';

const OfferDetailCard = () => {
  const { user } = useUserStore();
  const { offer } = useDemandStore();

  const handleSum = () => {
    const total = offer.total || 0;
    const commissionPageCut = 0.25;
    const totalDentist = total * 1.012 + commissionPageCut;
    return totalDentist.toFixed(2);
  };

  const dateFormat = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  return (
    <Card className="p-2  md:p-4 rounded-lg  bg-white">
      <CardContent>
        <div className="flex justify-between py-10 md:py-[50px] gap-10">
          <h2 className="font-urbanist ">
            <strong> Propuesta: </strong>
          </h2>
          <div className="flex flex-col font-urbanist  ">
            <label>
              <strong> Fecha de entrega propuesta: </strong>
              {dateFormat(offer.deliveryDateIntent)}
            </label>
          </div>
        </div>

        <p className="font-inter mb-4">{offer.description}</p>

        <div className="flex justify-end font-urbanist">
          <div className="flex flex-col gap-2">
            {user?._id != offer.seller._id ? (
              <div className="flex flex-col text-end space-y-3">
                <div className="flex flex-col text-end">
                  <label>
                    <strong>Trabajo</strong>
                  </label>
                  <label>{offer.total || 0} €</label>
                </div>
                {/* <div className="flex flex-col text-end">
                  <label>
                    <HoverCard>
                      <HoverCardTrigger>
                        <strong>Comisión plataforma de pago</strong>
                      </HoverCardTrigger>
                      <HoverCardContent>
                        <p className="text-justify">
                          Para garantizar un pago seguro, Oklusion utiliza
                          Stripe, la plataforma de pagos más grande y segura.
                        </p>
                        <br />
                        <p className="text-justify">
                          Para asegurar la calidad, tu pago no será transferido
                          al laboratorio hasta que el pedido haya sido recibido
                          y hayas confirmado que cumple con las expectativas.
                          Tiempo de espera máximo 90 días.
                        </p>
                      </HoverCardContent>
                    </HoverCard>
                  </label>
                  <label>
                    {offer.commissionPage || 1.2}% +{' '}
                    {offer.commissionPageCut || 0.25} €
                  </label>
                </div>
                <div className="flex flex-col text-end">
                  <label>
                    <strong>Total a pagar</strong>
                  </label>
                  <label>{offer.totalDentist || handleSum()} €</label>
                </div> */}
              </div>
            ) : (
              <div className=" flex flex-col  text-end space-y-3">
                <div className="flex flex-col text-end">
                  <label>
                    <strong>Trabajo + envío</strong>
                  </label>
                  <label>{offer.subTotal + offer.commission || 0} €</label>
                </div>

                {/* <div className="flex flex-col text-end">
                  <label>
                    <strong>Comisión</strong>
                  </label>
                  <label>{offer.commission || 0} €</label>
                </div>

                <div className="flex flex-col text-end">
                  <label>
                    <strong>Total a recibir</strong>
                  </label>
                  <label>{offer.subTotal || 0} €</label>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OfferDetailCard;
