import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getUsers,
  getUser,
  getProfile,
  updateProfile,
  updateUser,
  login,
  updateVerifyEmail,
  register,
} from '@/services/auth';
import useUserStore from '@/store/userStore';
import useDemandStore from '@/store/DemandStore';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import useMessageStore from '@/store/MessageStore';
import useNotificationStore from '@/store/Notificationstore';
import { loginSchema } from '@/schemas/loginSchema';
import { registerSchema } from '@/schemas/registerSchema';

export const useFetchUsers = (searchParams) => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = Object.fromEntries(searchParams || []);
      const queryString = new URLSearchParams(params).toString();
      const { users, total } = await getUsers({ queryString });
      setUsers(users);
      setTotal(total);
    } catch (error) {
      setError(error.message || 'Error fetching users');
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return { users, total, loading, error, refetch: fetchUsers };
};

export const useFetchUser = () => {
  const { user, setUser, logout } = useUserStore();
  const { clearDemandStore } = useDemandStore();
  const { clearMessages } = useMessageStore();
  const { clearNotifications } = useNotificationStore();
  const { clearOutsourcingStore } = useOutsourcingStore();

  const handleLogout = () => {
    setUser(null);
    clearDemandStore();
    clearOutsourcingStore();
    clearMessages();
    clearNotifications();
    logout();
  };

  return { user, handleLogout };
};

export const useFetchProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      setError(null);
      try {
        const fetchedProfile = await getProfile();
        setProfile(fetchedProfile);
      } catch (error) {
        setError(error.message || 'Error fetching profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return { profile, loading, error };
};

export const useUpdateProfile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const update = async (profileData) => {
    setLoading(true);
    setError(null);
    try {
      await updateProfile(profileData);
    } catch (error) {
      setError(error.message || 'Error updating profile');
    } finally {
      setLoading(false);
    }
  };

  return { update, loading, error };
};

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const update = async (userId, userData) => {
    setLoading(true);
    setError(null);
    try {
      await updateUser(userId, userData);
    } catch (error) {
      setError(error.message || 'Error updating user');
    } finally {
      setLoading(false);
    }
  };

  return { update, loading, error };
};

export function useLogin() {
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const { setUser } = useUserStore();
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      const { user, token } = await login(data);
      if (token) {
        setUser(user, token);
        navigate('/dashboard');
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error(err.response.data.msg);
        setShowVerifyButton(true);
      } else {
        toast.error('Correo o contraseña incorrecta');
      }
    }
  };

  const handleRequestVerifyEmail = async () => {
    try {
      const response = await updateVerifyEmail({
        email: form.getValues('email'),
      });
      if (response) {
        toast.success('Enviamos un correo');
        setShowVerifyButton(false);
      }
    } catch (err) {
      toast.error(err.response.data.msg);
    }
  };

  return {
    form,
    showVerifyButton,
    onSubmit,
    handleRequestVerifyEmail,
  };
}

export function useRegister() {
  const [searchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState('');
  const { user } = useUserStore();
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      role: undefined,
      terms: false,
    },
  });

  useEffect(() => {
    const referral = searchParams.get('referralCode');
    if (referral) {
      setReferralCode(referral);
    }
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate, searchParams]);

  const onSubmit = async (data) => {
    try {
      const response = await register({
        email: data.email,
        password: data.password,
        role: data.role,
        referralCode,
      });
      if (response.user) {
        toast.success('Registro Exitoso');
        navigate('/');
      }
    } catch (err) {
      toast.error(
        err.response?.data?.msg || 'Registration failed. Please try again.',
      );
    }
  };

  return { form, onSubmit, referralCode };
}
