import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createbillLabCase = async (billLabCaseData) => {
  try {
    const formData = new FormData();
    for (const key in billLabCaseData) {
      formData.append(key, billLabCaseData[key]);
    }
    const response = await axiosInstance.post('/billLabCase', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getbillLabCase = async (caseId) => {
  try {
    const response = await axiosInstance.get(`/billLabCase/${caseId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching billLabCase:', error);
    throw error;
  }
};

export const getbillLabCasesAsAdmin = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(
      `/billLabCase/asAdmin?${queryString}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting billLabCases:', error);
    throw error;
  }
};

export const getbillLabCasesAsSeller = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(
      `/billLabCase/asSeller?${queryString}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting billLabCases:', error);
    throw error;
  }
};
export const getbillLabCases = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/billLabCase?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting billLabCases:', error);
    throw error;
  }
};

export const getBillingDataAsAdmin = async (caseId) => {
  try {
    const response = await axiosInstance.get(
      `/billLabCase/demand/${caseId}/asAdmin`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching billing data:', error);
    throw error;
  }
};

export const getBillingData = async (caseId) => {
  try {
    const response = await axiosInstance.get(`/billLabCase/demand/${caseId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching billing data:', error);
    throw error;
  }
};

export const rejectBillLabCase = async (billLabCaseId, billLabCaseData) => {
  try {
    const response = await axiosInstance.put(
      `/billLabCase/reject/${billLabCaseId}`,
      billLabCaseData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error rejecting billLabCase:', error);
    throw error;
  }
};

export const acceptBillLabCase = async (billLabCaseId, billLabData) => {
  try {
    const response = await axiosInstance.put(
      `/billLabCase/accept/${billLabCaseId}`,
      billLabData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error accepting billLabCase:', error);
    throw error;
  }
};

export const deleteBillLabCase = async (billLabCaseId) => {
  try {
    const response = await axiosInstance.delete(
      `/billLabCase/${billLabCaseId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error deleting billLabCase:', error);
    throw error;
  }
};
