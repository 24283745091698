import { useEffect, useState, useCallback } from 'react';
import {
  getReviews,
  createReview,
  getReviewsAsAdmin,
  updateReviewAsAdmin,
  getReview,
} from '@/services/review';
import { reviewSchema, reviewEditSchema } from '@/schemas/reviewSchema';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';

import { useUserStore } from '@/store/userStore';
// import { toast } from 'react-toastify';

export const useFetchReviews = (searchParams) => {
  const { user } = useUserStore();
  const [reviews, setReviews] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReviews = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = Object.fromEntries(searchParams);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getReviews;
      } else if (user.role === 'DENTIST_ROLE') {
        fetcher = getReviews;
      } else {
        fetcher = getReviewsAsAdmin;
      }

      const data = await fetcher({ queryString });
      setReviews(data.reviews);
      setTotal(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch Reviews');
      console.error('Failed to fetch Reviews:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchReviews();
  }, [searchParams, fetchReviews]);

  return { reviews, loading, error, total, fetchReviews };
};

export function useCreateReview(caseId, outsourcingId, onSuccess) {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(reviewSchema),
    defaultValues: {
      description: '',
      rating: 0,
      isPrivate: false,
    },
  });

  const onSubmit = async (data) => {
    const reviewData = {
      ...data,
      demand: caseId,
      outsourcing: outsourcingId,
    };

    try {
      setLoading(true);
      const response = await createReview(reviewData);
      if (response) {
        form.reset();
        toast.success('Reseña creada con éxito');
        onSuccess();
      }
    } catch (error) {
      toast.error('Error al crear la reseña');
      console.error('Error creating review:', error);
    } finally {
      setLoading(false);
    }
  };

  return { form, onSubmit, loading };
}

export function useReviewEdit(reviewId, onSuccess) {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(reviewEditSchema),
    defaultValues: {
      description: '',
      rating: 0,
      isPrivate: false,
    },
  });

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const { review } = await getReview({ id: reviewId });
        form.reset({
          description: review.description,
          rating: review.rating,
          isPrivate: review.isPrivate,
        });
      } catch (error) {
        console.error('Error fetching review:', error);
        toast.error('Error al cargar la reseña');
      }
    };

    if (reviewId) {
      fetchReview();
    }
  }, [reviewId, form]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await updateReviewAsAdmin({
        id: reviewId,
        reviewData: data,
      });
      if (response) {
        toast.success('Reseña editada con éxito');
        onSuccess();
      }
    } catch (error) {
      toast.error('Error al editar la reseña');
      console.error('Error editing review:', error);
    } finally {
      setLoading(false);
    }
  };

  return { form, onSubmit, loading };
}
