import * as z from 'zod';

const baseSchema = z.object({
  description: z.string().min(1, 'Detalles son requeridos'),
});

export const creatorRevisionSchema = baseSchema;

export const responderRevisionSchema = baseSchema.extend({
  deliveryDateIntent: z.string().min(1, 'Fecha de entrega es requerida'),
});
