import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { createRevision, createRevisionResponse } from '@/services/revision';
import {
  createRevisionOutsorsing,
  createRevisionOutsorsingResponse,
} from '@/services/revision';
import useUserStore from '@/store/userStore';
import useOutsourcingStore from '@/store/OutsourcingStore';
import {
  dentistRevisionSchema,
  sellerRevisionSchema,
} from '@/schemas/revisionSchema';

import {
  creatorRevisionSchema,
  responderRevisionSchema,
} from '@/schemas/revisionOutsourcingSchema';

export function useRevision(caseId, onSuccess) {
  const [loading, setLoading] = useState(false);
  const { user } = useUserStore();

  const isDentist = user.role === 'DENTIST_ROLE';

  const form = useForm({
    resolver: zodResolver(
      isDentist ? dentistRevisionSchema : sellerRevisionSchema,
    ),
    defaultValues: {
      description: '',
      ...(isDentist ? {} : { deliveryDateIntent: '' }),
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let response = null;

      if (isDentist) {
        response = await createRevision({ ...data, demand: caseId });
      } else {
        response = await createRevisionResponse({ ...data, demand: caseId });
      }

      if (response) {
        toast.success(
          isDentist
            ? 'Revisión creada exitosamente'
            : 'Revisión respondida exitosamente',
        );
        onSuccess();
      }
    } catch (error) {
      toast.error('Error al crear la revisión');
      console.error('Error creating revision:', error);
    } finally {
      setLoading(false);
    }
  };

  return { form, onSubmit, loading, isDentist };
}

export function useRevisionOutsourcing(outsourcingId, onSuccess) {
  const [loading, setLoading] = useState(false);
  const { user } = useUserStore();
  const { outsourcing } = useOutsourcingStore();

  const isCreator = user?._id === outsourcing.user?._id;

  const form = useForm({
    resolver: zodResolver(
      isCreator ? creatorRevisionSchema : responderRevisionSchema,
    ),
    defaultValues: {
      description: '',
      ...(isCreator ? {} : { deliveryDateIntent: '' }),
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let response = null;

      if (isCreator) {
        response = await createRevisionOutsorsing({
          ...data,
          outsourcing: outsourcingId,
        });
      } else {
        response = await createRevisionOutsorsingResponse({
          ...data,
          outsourcing: outsourcingId,
        });
      }

      if (response) {
        toast.success(
          isCreator
            ? 'Revisión creada exitosamente'
            : 'Revisión respondida exitosamente',
        );
        onSuccess();
      }
    } catch (error) {
      toast.error('Error al crear la revisión');
      console.error('Error creating revision:', error);
    } finally {
      setLoading(false);
    }
  };

  return { form, onSubmit, loading, isCreator };
}
