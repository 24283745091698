import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import LabCard from '@/components/Cards/LabCard';
import OfferDetailCard from '@/components/Cards/OfferDetailCard';
import {
  getDemandById,
  getDemandByIdAsSeller,
  updateDemandNotifications,
} from '@/services/demand';
import { Card, CardContent } from '@/components/ui/card';
import useUserStore from '@/store/userStore';
import useDemandStore from '@/store/DemandStore';
import useNotificationStore from '@/store/Notificationstore';
import { useFetchOffer } from '@/hooks/useOffer';
import { useCreateOrder } from '@/hooks/useOrder';
import { loadStripe } from '@stripe/stripe-js';
import { createPaymentCheckout } from '@/services/payment.service';

const OfferPage = () => {
  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK);
  const { user, logout } = useUserStore();
  const { offer } = useDemandStore();
  const { addNotification } = useNotificationStore();
  const { offerId } = useParams();
  const { demand, setDemand } = useDemandStore();
  const socket = useRef(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { error: errorOffer, fetchOffer } = useFetchOffer(offerId);
  const { order, loading: loadingOrder, createOrder } = useCreateOrder();

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });

    // Join the room for this specific offer
    socket.current.emit('joinRoom', offerId);

    // Socket event handlers

    const handleReceiveOrder = (order) => {
      fetchOffer();
    };

    socket.current.on('receiveOrder', handleReceiveOrder);

    return () => {
      socket.current.emit('leaveRoom', offerId);
      socket.current.disconnect();
    };
  }, [offerId, user, addNotification]);

  const fetchDemand = async (demandId) => {
    try {
      const { demand } =
        user.role === 'SELLER_ROLE'
          ? await getDemandByIdAsSeller(demandId)
          : await getDemandById(demandId);
      await setDemand(demand);
    } catch (error) {
      console.error('Failed to fetch demand:', error);
      toast.error('Error fetching demand data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offer._id === offerId) fetchDemand(offer.demand._id);
  }, [offer, offerId]);

  useEffect(() => {
    if (order && !loadingOrder) {
      socket.current.emit('acceptOffer', { order });
      toast.success('Propuesta aceptada exitosamente');
      navigate(`/cases/${offer.demand._id}`);
    }
  }, [order, loadingOrder]);

  const handleAccept = async () => {
    if (offer.status === 'accepted') {
      toast.error('La propuesta ya ha sido aceptada.');
      return;
    }

    try {
      await createOrder({ offer: offerId });
    } catch (error) {
      console.error('Error while accepting the offer:', error);
      toast.error(
        error.response?.data?.message ||
          'Error al aceptar la propuesta. Por favor, inténtelo de nuevo.',
      );
    }
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    const amount = offer.totalDentist;
    const caseId = demand._id;

    const data = { amount, currency: 'usd', caseId, offerId };
    const { sessionId } = await createPaymentCheckout(data);

    // Redirigir al usuario a Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error) {
      console.error('Error al redirigir a Stripe:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <label>
          <svg className="animate-spin h-5 w-5 mr-2 text-white" />
          Cargando...
        </label>
      </div>
    );
  }

  if (errorOffer) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <label>Propuesta no encontrada</label>
      </div>
    );
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3">
        {user.role != 'SELLER_ROLE' && <LabCard />}
        {demand.status !== 'pending' && user.role === 'SELLER_ROLE' && (
          <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
            <CardContent className="w-full flex flex-col md:flex-row ">
              <div className="mt-4 text-sm text-gray-700">
                <h4 className="font-semibold">
                  Dirección para el envio del pedido
                </h4>
                <p>{demand?.clinic?.clinicName}</p>
                <p>{demand?.clinic?.clinicstreet}</p>
                <p>
                  {demand?.clinic?.postalCode} {demand?.clinic?.city}{' '}
                  {demand?.clinic?.province}
                </p>
              </div>
            </CardContent>
          </Card>
        )}
        <OfferDetailCard />

        {offer.status === 'created' && user.role === 'DENTIST_ROLE' && (
          <div className="py-10 flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-inter font-bold py-2 px-4 rounded"
              onClick={handleAccept}
              disabled={loadingOrder || offer.status === 'accepted'}
            >
              {loadingOrder ? 'Aceptando...' : 'Aceptar'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferPage;
